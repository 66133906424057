import React, { Component }    from 'react';
import { graphql, navigate }   from 'gatsby';
import Img                     from 'gatsby-image';
import { Container, Row, Col } from 'react-grid-system'
import { FaInfoCircle }        from 'react-icons/fa';
import styled                  from '@emotion/styled';

import HeaderImage    from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Heading        from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer         from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper        from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import { Button }     from '@interness/web-core/src/components/elements/Button/Button';
import Link           from '@interness/web-core/src/components/elements/Link/Link';
import ContactSidebar from '@interness/web-core/src/components/structure/ContactSidebar/ContactSidebar';

export const Form = styled.form`
  .hidden {
    display: none;
  }
  label {
    display: block;
  }
  input {
    padding: 5px 10px;
    width: 100%;
    border: 1px solid rgb(204, 204, 204);
  }
  textarea {
    width: 100%;
    height: 130px;
    padding: 5px 10px;
    resize: none;
    border-color: rgb(204, 204, 204);
  }
  div > div {
    margin-bottom: 8px;
  }
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  li {
    margin: 0;
    padding: 8px 0;
    svg {
      margin-right: 10px;
    }
  }
`;

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

class GutsteinPage extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute('action'), {
        state: this.state,
      }))
      .catch(error => alert(error));
  };


  render() {
    return (
      <>
        <HeaderImage>
          <Img fluid={this.props.data.headerImage.childImageSharp.fluid}/>
        </HeaderImage>
        <Wrapper>
          <Spacer/>
          <section>
            <Heading>Gutsteine</Heading>
          </section>
          <Spacer/>
          <section>
            <ol>
              <li><b>BITTE FÜLLEN SIE DAS FORMULAR VOLLSTÄNDIG AUS.</b></li>
              <li>Bitte geben Sie in das Feld "NAME" Ihren Vor- und Nachnamen und in das Feld "EMAIL" Ihre Email Adresse
                ein.
              </li>
              <li>Bitte geben Sie in das Feld "RECHNUNGSADRESSE" Ihre vollständige Adresse ein.</li>
              <li>Wenn Sie wünschen, dass der Gutstein direkt einer anderen Person zugeschickt werden soll, geben Sie in
                das Feld "LIEFERADRESSE" den vollständigen Namen und die dazugehörige Adresse ein.
              </li>
              <li>Wenn Sie einen oder mehrere Gutsteine wünschen, tragen Sie in das Feld "ANZAHL" die gewünschte Menge
                an Gutsteinen ein.
              </li>
              <li>Bitte nennen Sie uns in dem Feld "WERT" den Wert des Gutsteines in Eur(Wir haben Gutsteine für 25.-€,
                30.-€, 50.- €oder 100.-€ ) Solange der Vorrat reicht.
              </li>
              <li>Gerne verpacken wir Ihren Gutstein nach Wunsch für eine Frau oder einen Mann.</li>
              <li>Wenn Sie noch eine zusätzliche Nachricht für uns haben können Sie diese in das Nachrichtenfeld
                eingeben.
              </li>
              <li>Drücken Sie auf "JETZT KAUFEN".</li>
              <li>Nach Absenden der Bestellung erhalten Sie von uns eine Rechnung mit dem von Ihnen bestellten
                Gutschein/en und unsere Bankverbindung. Nach Eingang der Zahlung erhalten Sie oder der Beschenkte
                den/die Gutscheine. Die Portokosten tragen wir für Sie.
              </li>
            </ol>
          </section>
          <Spacer/>
          <section>
            <Container>
              <Row>
                <Col md={6}>
                  <h3>Kontakt Info</h3>
                  <ContactSidebar/>
                  <Spacer height={20}/>
                  <List>
                    <li>
                      <FaInfoCircle/><Link to={'/widerrufsrecht'}>Widerrufsrecht</Link>
                    </li>
                    <li>
                      <FaInfoCircle/><Link to={'/widerrufsformular.pdf'} external={true}>Widerrufsformular</Link>
                    </li>
                  </List>
                </Col>
                <Col md={6}>
                  <Form
                    name='stone-voucher'
                    method='POST'
                    action='/thanks-voucher/'
                    data-netlify='true'
                    data-netlify-honeypot='bot-field'
                    onSubmit={this.handleSubmit}>
                    <div className='hidden'>
                      <input type='hidden' name='form-name' value='stone-voucher'/>
                      <label hidden>
                        Don’t fill this out:{' '}
                        <input name='bot-field' onChange={this.handleChange}/>
                      </label>
                    </div>
                    <Container fluid>
                      <Row>
                        <Col md={6}>
                          <label htmlFor='name'>Name</label>
                          <input type='text' name='name' placeholder='Jon Doe' required
                                 onChange={this.handleChange}/>
                        </Col>
                        <Col md={6}>
                          <label htmlFor='email'>Email</label>
                          <input type='email' name='email' placeholder='jon@doe.de' required
                                 onChange={this.handleChange}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <label htmlFor='invoiceAddress'>Rechnungsadresse</label>
                          <textarea name='invoiceAddress' placeholder='Musterstraße 12, 12345 Musterstadt' required
                                    onChange={this.handleChange}/>
                        </Col>
                        <Col md={6}>
                          <label htmlFor='shippingAddress'>Lieferadresse</label>
                          <textarea name='shippingAddress' placeholder='Musterstraße 12, 12345 Musterstadt'
                                    onChange={this.handleChange}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <label htmlFor='number'>Anzahl</label>
                          <input type='number' name='number' placeholder='1' required
                                 onChange={this.handleChange}/>
                        </Col>
                        <Col md={4}>
                          <label htmlFor='value'>Wert in €</label>
                          <input type='number' name='value' placeholder='50 €' required
                                 onChange={this.handleChange}/>
                        </Col>
                        <Col md={4}>
                          <label htmlFor='packaging'>Verpackung</label>
                          <input type='text' name='packaging' placeholder='Weblich / Männlich' required
                                 onChange={this.handleChange}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <label htmlFor='message'>Nachricht</label>
                          <textarea name='message' placeholder='Hallo...'
                                    onChange={this.handleChange}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div style={{ textAlign: 'right' }}>
                            <Button primary type='submit'>Jetzt kaufen</Button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                </Col>
              </Row>
            </Container>
          </section>
          <div style={{ textAlign: 'center' }}>
            <Button as={Link} to={'/gutschein'}>Hier geht es zu den Gutscheinen</Button>
          </div>
          <Spacer/>
          <p style={{ fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center' }}>Wenn noch Fragen offen sein
            sollten,
            rufen Sie uns einfach kurz an: Telefon 0212 / 204654</p>
          <Spacer/>
        </Wrapper>
      </>
    );
  }
}

export default GutsteinPage;

export const query = graphql`
  query GutsteinPageQuery {
    headerImage: file(relativePath: {eq: "headers/gutschein.jpg"}) {
      ...HeaderImages
    }
  }
`;